import React from "react"
import "./tooltip.css"


class Tooltip extends React.Component {

    render() {
        return (
            <div id="tooltip">
                {this.props.word}
                <span id="tooltip-text">{this.props.definition}</span>
            </div>
        )
    }
}

export default Tooltip