
import React from "react"
import { Switch, Route } from "react-router-dom";
import "./App.css"

import { AppBar } from "./components";
import { Positions, E404, Redirect, Admin, Ranks, About } from "./pages";

function App() {
  window.localStorage.sitename = process.env.REACT_APP_SITENAME;
  //const config = 1;
  return (
    <div>
      <header>
        <AppBar />
      </header>

      <main>
        <Switch>
          <Route exact path="/" component={Positions} />
          <Route exact path="/redirect" component={Redirect} />
          <Route exact path="/about" component={About} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/joinpro" component={Ranks} />
          <Route component={E404} />
        </Switch>
      </main>
    </div>
  );
}

export default App