
import React from "react"
import "./positions.css";

import { Position } from "../../components"

class Positions extends React.Component {
    
    state = { plays: {}, loaded: false, sort: "entry_date"};

    constructor(props) {
        super(props);
        this.get_plays();
    }

    render() {
        return (
            <div className="main">
                {this.state.loaded ?
                    this.state.plays.sort((a, b) => {
                        return b[this.state.sort] - a[this.state.sort];
                    }).map(play => {
                        return <Position play={play} editing={false} key={play['id']}/>
                    }) 
                : null}
            </div>
        )
    }

    get_plays() {
        if (!this.state.loaded) {
            fetch("https://api.ifficient.tech/v1/plays/" + window.localStorage.sitename, {method: 'GET'})
            .then(response => response.json()).then(data => {
                this.setState({
                        plays: data.data.plays,
                        loaded: true
                    });
                });
            }
        }
    }

export default Positions