import React from "react"

import "./appbar.css";
import logo from "./logo.svg";

class AppBar extends React.Component {

    constructor(props) {
        super(props);
        this.expandMenu = this.expandMenu.bind(this);
    }

    expandMenu(e) {
        e.preventDefault();
        document.getElementById("bar").classList.toggle("menu-hide");
    }

    render() {
        return (
            <div id="bar" className="menu-hide">
                <div className="title">
                    <a className="title" href="/">
                        <img alt='Site logo' className="titleimage" src={logo} />
                        <div className="titlestack">
                            <span className="titletext">SpringLoading</span>
                            <span className="subtitle">powered by ifficient.tech</span>
                        </div>
                    </a>
                    <button className="expand-button" onClick={this.expandMenu}>
                        <i className="fas fa-bars fa-lg"></i>
                    </button>
                </div>
                <div className="links">
                    <a className="link" href="https://discord.gg/JwEFYSxc">
                        <i className="linkimg fab fa-discord"></i>
                        <span className="linktext">Join the Discord</span>
                    </a>
                    <a href="/about" className="link">
                        <i className="linkimg fas fa-info-circle"></i>
                        <span className="linktext">About</span>
                    </a>
                    <a href="/joinpro" className="link">
                        <i className="linkimg fas fa-chart-line"></i>
                        <span className="linktext">Go Pro</span>
                    </a>
                    <ProfileIndicator />
                </div>
            </div>
        )
    }
}

class ProfileIndicator extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = { identifier: null};
        this.discord_auth = this.discord_auth.bind(this);
    }

    componentDidMount(){
        const username = window.localStorage.username;
        this.setState({
            identifier: username,
        });                
    }

    discord_auth(e){
        e.preventDefault();
        document.location.href = 'https://discord.com/api/oauth2/authorize?client_id=811870685223845939&redirect_uri=http%3A%2F%2Fwww.ifficient.tech%2Fredirect&response_type=code&scope=identify%20email&prompt=none&state='+window.localStorage.sitename;
    }

    render() {
        return (
            <>
            {this.state.identifier == null ?
                <a className="link" onClick={this.discord_auth} href="/">
                    <i className="linkimg fas fa-user-circle"></i>
                    <span className="linktext">Login</span>
                </a>
            :
                <p className="link">
                    <i className="linkimg fas fa-user-circle"></i>
                    <span className="linktext">{this.state.identifier}</span>
                </p>
            }
            </>
        )
    }
}

export default AppBar