import React from "react"
import "./e404.css";

class E404 extends React.Component {

    render() {
        return (
            <div className="main">
                <div className="message-container">
                    <p className="big-message">404</p>
                    <p className="small-message">You seem lost</p>
                </div>
            </div>
        )
    }
}

export default E404