import React from "react"
import "./about.css";

class About extends React.Component {

    render() {
        return (
            <div className="main">
                <div className="about-text-container">
                    <p className="about-text-header"><b>Welcome to the SpringLoading community!</b></p>
                    <p className="about-text">
                        This community is based around a bot programmed by connorvo which analyses the SEC"s 
                        EDGAR database to find irregular patterns in company filings. Once something is found 
                        the community is alerted of the findings. This is a data community, not a stock picking 
                        community. We trade based on facts, not intuition.
                    </p>
                    <p className="about-text">
                        On the homepage you can view every past finding of the bot given to the community in chronological
                        order. (Click on the entries to see extra information!) None are deleted or edited and all data is
                        pulled straight from the markets. 
                    </p>
                    <p className="about-text">
                        New updates go out roughly once per night when the EDGAR database is updated 
                    </p>
                    <p className="about-text">This website was made with &#128154; by <a href="https://github.com/GarrettPeake">Peake</a> </p>
                </div>
            </div>
        )
    }
}

export default About