import React from "react"
import "./admin.css";

class Admin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="main">
                {'admin' in window.localStorage.ranks ?
                    <>
    
                    </>
                : <p>| Error: not currently logged in as an admin</p> }
            </div>
        )
    }
}

export default Admin