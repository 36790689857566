import React from "react"
import { ResponsiveLine } from "@nivo/line"

import "./chart.css"

class Chart extends React.Component {
    render() {
        return (
            <div className="chart">
                <ResponsiveLine
                    data={this.props.ticker_data}
                    margin={{ top: 4, right: 0, bottom: 4, left: 50}}
                    xScale={{ format: "%m/%d/%Y %H:%M", type: "time" }}
                    xFormat="time: %m/%d/%Y %H:%M"
                    yScale={{ type: "linear", min: "auto", max: "auto", stacked: false, reverse: false }}
                    yFormat=" >-.2f"
                    curve="linear"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickValues: "every 1 day",
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        format: "%m/%d/%Y",
                        legend: "Time",
                        legendOffset: 36,
                        legendPosition: "middle"
                      }}
                    anchor="left"
                    axisLeft={{
                        orient: "left",
                        tickSize: 0,
                        tickPadding: 7,
                        tickRotation: 0,
                        legend: "Price",
                        legendOffset: -40,
                        legendPosition: "middle"
                    }}
                    theme={{
                        "textColor": "rgb(193, 243, 213)"
                    }}
                    colors={{scheme : "dark2"}}
                    enableGridX={false}
                    lineWidth={0}
                    enablePoints={false}
                    enableArea={true}
                    areaOpacity={1}
                    useMesh={true}
                />
            </div>
        )
    }
}

export default Chart