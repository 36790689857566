import React from "react"
import "./ranks.css";

class Ranks extends React.Component {

    constructor(props) {
        super(props);
        this.state = { opened: false }
        this.trigger_drop = this.trigger_drop.bind(this);
    }

    trigger_drop() {
        this.setState(state => ({
            opened: !this.state.opened
        }));
    }

    render() {
        return (
            <div className="main">
                
            </div>
        )
    }
}

export default Ranks