import React from "react"
import dayjs from "dayjs"
import "./position.css"
import { Chart } from "../"

var dayOfYear = require("dayjs/plugin/dayOfYear")

class Position extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            is_open: false,
            ticker_data: false,
            save_text: "Save Changes"
        };
        dayjs.extend(dayOfYear)
        this.handleClick = this.handleClick.bind(this);
        this.get_data = this.get_data.bind(this);
    }

    get_data() {
        fetch('https://api.ifficient.tech/v1/data/'+window.localStorage.sitename , {
            method: 'POST',
            body: JSON.stringify({id: this.props.play['id']})
        })
        .then(response => response.json()).then(data => {
            var new_data = {};
            for (const [key, value] of Object.entries(data.data.data)) {
                new_data[key] = [{id: key, data: value }]
            }
            this.setState({
                ticker_data: new_data
            });
        })
    }

    format_dates(entry, exit) {
        const [e_date, e_time] = this.format_date(entry);
        const [x_date, x_time] = this.format_date(exit);
        if(e_date === x_date){
            return e_date + " " + e_time + "→" + x_time;
        }
        return e_date + (e_time ? " " + e_time : "") + " → " + x_date + (x_time ? " " + x_time : "");
    }

    format_date(timestamp){
        if(timestamp !== "NA"){
            const date = dayjs.unix(timestamp);
            const today = dayjs();
            const time_str = date.second() !== 0 ? date.format("h:mm:ss") : "";
            var date_str = date.format("D MMM" + (date.year() !== today.year() ? ", YY" : ""));
            if(date.dayOfYear() === today.dayOfYear() && date.year() === today.year()){
                date_str = "Today"
            }
            if(date.dayOfYear() === today.dayOfYear() - 1 && date.year() === today.year()){
                date_str = "Yesterday"
            }
            return [date_str, time_str];
        }
        return ["Still Active", ""]
    }

    get_headline() {
        var ticker = '';
        this.props.play['equities'].forEach(element => {ticker += ', ' + element['ticker']});
        ticker = ticker.substr(2,ticker.length);
        var entry = this.props.play["entry_date"];
        var exit = this.props.play["exit_date"];
        return (
            <><span> {ticker} </span> <span> {this.format_dates(entry, exit)}</span></>
        );
    }

    handleClick() {
        this.setState(state => ({
            is_open: !state.is_open,
            opened: true
        }));
        if (!this.state.opened && !this.props.editing)
            this.get_data();
    }

    render() {
        return (
            !this.props.editing ?
                <div className="position">
                    <div className={this.state.is_open ? "headbar-opened" : "headbar-closed"} onClick={this.handleClick}>
                        <pre className="headline">{this.get_headline()}</pre>
                    </div>
                    {this.state.is_open ?
                        <div className="drop-box">
                                {// Main text
                                    this.props.play["text"] ?
                                    <span className="analysis-text">{this.props.play["text"]}</span>
                                    : null
                                }
                                { // Iterate through
                                    this.props.play['equities'].sort((a, b) => {
                                        return b['ticker'] - a['ticker'];
                                    }).map(equity => {
                                        return (
                                            <div key={equity.ticker} className='flex justify-evenly w-full'>
                                                {this.state.ticker_data ? 
                                                    <div className="chart-container">
                                                        <Chart ticker_data={this.state.ticker_data[equity.ticker]} />
                                                    </div>
                                                :
                                                    <div className="chart-container">
                                                        <span className="analysis-text">Chart loading...</span>
                                                    </div>
                                                }
                                                <div className="analysis-container">
                                                    <span className="analysis-text">{equity['ticker']}</span>
                                                    {equity['analyses'].map(analysis => { // Create all of the analysis texts
                                                            return <span className="analysis-text">{analysis["text"]}</span>
                                                        })
                                                    }
                                                    {this.state.ticker_data ?
                                                        <span className="analysis-text">Price at entry: ${this.state.ticker_data[equity.ticker][0].data[0].y}</span>
                                                        : null}
                                                    {this.state.ticker_data ?
                                                        (this.props.play["exit_date"] ?
                                                            <span className="analysis-text">Price at exit: ${this.state.ticker_data[equity.ticker][0].data[this.state.ticker_data[equity.ticker][0].data.length - 1].y}</span>
                                                        : <span className="analysis-text">Current Price: ${this.state.ticker_data[equity.ticker][0].data[this.state.ticker_data[equity.ticker][0].data.length - 1].y}</span>
                                                        )
                                                    :null}
                                                </div>
                                            </div>
                                        )
                                    }) 
                                } 
                        </div>
                        : null}
                </div>
                : null
        )
    }
}

export default Position