import React from "react"

class Redirect extends React.Component {

    constructor(props) {
        super(props);
        this.state = { message: "Waiting on login", sub_message: "We're verifying your identity, hold tight" }
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        fetch("https://api.ifficient.tech/v1/auth/" + window.localStorage.sitename,
            {
                method: 'POST',
                body: JSON.stringify({
                    code: urlParams.get('code'),
                    provider: 'discord'
                })
            }
        ).then(response => response.json()).then(data => {
            if(data.status === 'success') {
                window.localStorage.username = data.data.username;
                window.localStorage.ranks = data.data.ranks;
                window.localStorage.token = data.data.token;
                this.setState(state => ({
                    message: "Login Succesful",
                    sub_message: "We're redirecting you back home"
                }));
                setTimeout(function () {
                    window.location.href = "/";
                }, 1500);
            } else {
                this.setState(state => ({
                    message: "Login Failed",
                    sub_message: "It seems Discord doesn't like you, We're sending you home to try again"
                }));
                setTimeout(function () {
                    window.location.href = "/";
                }, 1500);
            }
        });
    }

    render() {
        return (
            <div>
                <p className="txxl tcenter">{this.state.message}</p>
                <p className="tm tcenter">{this.state.sub_message}</p>
                <form className="button" action="/">
                    <input className="tm" type="submit" value="Home" />
                </form>
            </div>
        )
    }
}

export default Redirect